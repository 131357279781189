import {Component, OnDestroy, OnInit} from '@angular/core';

import './../assets/css/index.css';
import {ShareModule} from './shared/shareModule/share.module';
import {Location} from '@angular/common';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.css',
    ],
})
export class AppComponent implements OnInit, OnDestroy {

    constructor(
        private _shareService: ShareModule,

    ) {
    }


    ngOnInit() {

    }

    ngOnDestroy() {

    }

}
