import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../global';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public permission: string;

    constructor(private _httpClient: HttpClient) {

    }

    logout() {
        return this._httpClient.post<any>(glob.BASE_URL + 'intelisys/logout', {}, {observe: 'response'});
    }

}
