import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import swal from 'sweetalert';
import {BASE_URL} from '../global';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _router: Router) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    localStorage.removeItem('currentUser');
                    localStorage.removeItem('user');
                    localStorage.removeItem('process');
                    this._router.navigate(['login']);
                    // location.reload(true);
                }
                if (err.status === 400) {
                    if (err.url === BASE_URL + 'intelisys/refresh-token') {
                        localStorage.removeItem('currentUser');
                        localStorage.removeItem('user');
                        localStorage.removeItem('process');
                        this._router.navigate(['/login']);
                    }
                    swal('Error', err.error.message, 'error');
                }
                if (err.status === 404) {
                    swal('Error', err.error.message, 'error');
                }
                if (err.status === 422) {
                    if (err.error.message) {
                        swal('Error', err.error.message, 'error');
                    } else {
                        swal('Error', err.error.error.errors[0]);
                    }
                }
                if (err.status === 402) {
                    swal('Error', err.error.error.errors[0], 'error');
                }
                if (err.status === 504) {
                    swal('Error', 'Time out', 'error');
                }
                // console.clear();
                const error = err.error.message || err.statusText;
                return throwError(error);
            }));
    }
}
