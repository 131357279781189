import {Routes} from '@angular/router';
import {AuthGuard} from './shared/_guards/auth.guard';

export const appRouter: Routes = [
    {
        path: '',
        redirectTo: 'booking/create',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: './modules/login/login.module#LoginModule'
    },
    {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: './moduleCommon/master/master.module#MasterModule',
    },

];

